/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g transform="translate(-211 -18)" _fill="none" fill-rule="evenodd"><rect pid="0" _stroke="red" x="212" y="19" width="12" height="12" rx="1"/><path pid="1" _stroke="red" _fill="#00514A" stroke-linecap="square" d="M218 25v2"/><path pid="2" _fill="red" d="M217.5 22.545h1v1h-1z"/></g>'
  }
})
